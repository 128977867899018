<template>
  <Transition appear name="slide-down">
    <div
      v-if="displayBanner"
      :class="[
        'flex w-full items-center justify-center px-24 py-16',
        partnerDetails.bgColor,
      ]"
    >
      <RevIllustration
        alt="BackMarket"
        :height="16"
        :src="'/img/buyback/backMarketLogo.svg'"
        :width="124"
      />

      <span
        class="border-l-static-default-low mx-16 inline-block h-32 border-[1px]"
      >
      </span>

      <RevIllustration
        :alt="partnerDetails.label"
        :height="partnerDetails.iconHeight"
        :src="partnerDetails.iconSrc"
        :width="partnerDetails.iconWidth"
      />
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevIllustration } from '@ds/components/Illustration'

const tracking = useTracking()

interface PartnershipBannerProps {
  partner: string
}

const route = useRoute()

const PARTNERS = {
  playstation: {
    bgColor: 'bg-[#003791]',
    iconSrc: '/img/buyback/playStation.svg',
    iconHeight: 24,
    iconWidth: 132,
    label: 'PlayStation',
    eligibleCategory: ['gaming_console'],
  },
}

const props = defineProps<PartnershipBannerProps>()

const partnerDetails = computed(() => {
  return PARTNERS[props.partner as keyof typeof PARTNERS]
})

const displayBanner = computed(() => {
  return partnerDetails.value.eligibleCategory.includes(
    route.params.id as string,
  )
})

onMounted(() => {
  if (displayBanner.value) {
    const payload = {
      pageType: route.name as string,
      params: { ...route.params },
      partnershipSource: partnerDetails.value.label,
    }

    tracking.trackPartnershipBannerImpression(payload)
  }
})
</script>

<style>
.slide-down-enter-active,
.slide-down-leave-enter {
  transform: translateY(0%);
  opacity: 1;
  transition: all 200ms linear;
}
.slide-down-enter-from,
.slide-down-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
